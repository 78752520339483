import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { GiStarShuriken } from 'react-icons/gi'
import { RiMenu2Fill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import ButtonOutline from 'src/components/atoms/ButtonOutline'
import TextLoader from 'src/components/atoms/TextLoader'
import { toggleMenu } from 'src/redux/global/menuSlice'
import useSwr from 'swr'


const fether = async (url) => {
    const response = await axios(url);
    return response.data
}


const Sidebar = () => {
    const { pathname } = useLocation()
    const rk = localStorage.getItem("recent_keyword")
    const API = process.env.REACT_APP_API;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data, isLoading } = useSwr(API + '/category', fether)
    const goToPage = async (page) => {
        await navigate(page)
        dispatch(toggleMenu(false))
    }
    const [openCtg, setOpenCtg] = useState(pathname.includes("/ctg/"))
    const IconImage = ({ src }) => {
        const lowName = src.toLowerCase()
        let filename = '_ic_camera_act.svg';
        if (lowName.includes('video')) {
            filename = '_ic_video_act.svg'
        }
        if (lowName.includes('camera')) {
            filename = '_ic_camera_act.svg'
        }
        if (lowName.includes('processor')) {
            filename = '_ic_processor_act.svg'
        }
        return (
            <img
                src={`/icons/${filename}`}
                alt="icon" />
        )
    }

    const handleCategoryParent = () => {
        setOpenCtg(!openCtg)
    }

    useEffect(() => {
        setOpenCtg(pathname.includes("/ctg/"))
    }, [pathname])


    return (
        <div className='relative z-10 min-h-screen pt-24 md:pt-0'>
            <>
                <NavLink to={'/product'} className={({ isActive }) => `flex text-lg items-center gap-2 p-3 ${isActive ? "text-white bg-primary " : "text-[#7C7C7C]"}`}>
                    <div className='w-6'>
                        <GiStarShuriken fontSize={22} />
                    </div>
                    <div className='relative font-semibold'>New Product</div>
                </NavLink>
                <NavLink to={`/search/${rk}`} className={({ isActive }) => `flex text-lg items-center gap-2 p-3 ${isActive ? "text-white bg-primary" : "text-[#7C7C7C]"}`}>
                    <div className='w-6'>
                        <FiSearch fontSize={22} />
                    </div>
                    <div className='font-semibold'>Recent Search</div>
                </NavLink>
            </>
            <>
                <button
                    onClick={handleCategoryParent}
                    className={`
                            w-full items-center
                            ${openCtg ? "text-white bg-primary" : "text-[#7C7C7C]"}
                             text-lg flex gap-2 relative p-3 font-semibold  duration-200`}>
                    <div className='w-6'>
                        <RiMenu2Fill fontSize={22} />
                    </div>
                    <span>Product List</span>
                </button>
                <div className={`${openCtg ? "" : "h-0"} duration-200 overflow-hidden`}>
                    {
                        data?.map(item => (
                            <Fragment key={item.id}>
                                <div className="mt-4" />
                                <NavLink
                                    state={item}
                                    to={{
                                        pathname: `/ctg/${item.slug}`
                                    }}
                                    className={({ isActive }) => `flex pl-8 text-md ${isActive ? "text-[#2FAB73]" : "text-[#7C7C7C] grayscale"}  items-center gap-2`}
                                >
                                    <div className='w-6'>
                                        <IconImage src={item.name} />
                                    </div>
                                    <div className='font-normal line-clamp-1 relative ctg-text'>{item.name}</div>
                                </NavLink>

                            </Fragment>
                        ))

                    }
                </div>
                {
                    isLoading && (
                        <div className="px-3 flex flex-col gap-4">
                            <TextLoader />
                            <TextLoader />
                            <TextLoader />
                        </div>
                    )
                }
            </>
            <div className='md:hidden block left-0 right-0 absolute bottom-0'>
                <div className="flex my-2 justify-center">
                    <ButtonOutline
                        onClick={() => goToPage("/contact")}
                        title={"Contact Us"} />
                </div>
                <div className='flex-1 p-3 flex justify-between bg-primary'>
                    {/* <button onClick={() => goToPage("/about")}>
                        About
                    </button> */}
                    <button onClick={() => goToPage("/gallery")}>
                        Gallery
                    </button>
                    <button onClick={() => goToPage("/service")}>
                        Service
                    </button>
                    <button onClick={() => goToPage("/product")}>
                        Product
                    </button>
                    <button onClick={() => goToPage("/articles")}>
                        Articles
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Sidebar