import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonOutline from 'src/components/atoms/ButtonOutline'

const ProductService = () => {
  const [data] = useState([
    {
      id: 9,
      title: 'Videotron and Videowall',
      image: '/images/services/1.png',
      description: `Experience stunning visuals and unparalleled flexibility with
      our videowall products, perfect for any business or organization
      looking to make a lasting impression.`,
      url: 'videowallvideotron'
    },
    {
      id: 10,
      title: 'Camera DVR/NVR',
      image: '/images/services/2.png',
      description:
        'Ensure the safety and security of your property with our high-tech CCTV systems, featuring advanced surveillance technology for monitoring and protection.',
      url: 'camera-dvrnvr'
    },
    {
      id: 11,
      title: 'Processor and Controller',
      image: '/images/services/3.png',
      description:
        'Optimize your system performance with our powerful processors and controllers, designed for seamless integration and efficient operation in any application.',
      url: 'processor-and-controller'
    },
    {
      id: 4,
      title: 'Service and Maintenance',
      image: '/images/services/4.png',
      description:
        'We accept the installation and maintenance of digital signage to provide your business marketing become more interactive to your customer.',
      url: 'service'
    }
  ])

  const navigate = useNavigate()
  const handleNavigate = item => {
    const path = item.url === 'services' ? item.url : '/ctg/' + item.url
    navigate(path, { state: { ...item } })
  }

  return (
    <>
      <div className='w-full px-4 max-w-md lg:px-0 mx-auto mb-12'>
        <h2 className='text-[24px] md:text-[35px] text-[#2E7462] mb-4 font-semibold text-center'>
          Our Product and Services
        </h2>
        <p className='text-[14px] md:text-[16px] text-[#4F5665] leading-[30px] text-center'>
          Discover the full range of our exceptional services and products,
          designed to meet all your needs.
        </p>
      </div>
      <div className='w-full max-w-5xl mx-auto px-4 lg-px-0'>
        <div className='grid md:grid-cols-2 gap-4 md:gap-8'>
          {data?.map((item, key) => (
            <div
              key={item.id}
              data-aos='fade-up'
              data-aos-duration={1000}
              data-aos-delay={key * 400}
              className='relative border rounded-xl overflow-hidden shadow-lg'
            >
              <div className='absolute p-8 left-0 right-0 top-0 bottom-0 flex flex-col justify-center items-start'>
                <h3 className='text-[#0B132A] text-[16px] md:text-[18px] font-semibold'>
                  {item.title}
                </h3>
                <p className='md:my-5 text-secondary text-[12px] md:text-[14px] w-full lg:w-1/2'>
                  {item.description}
                </p>
                <ButtonOutline
                  onClick={() => handleNavigate(item)}
                  title='Select'
                />
              </div>
              <img className='w-full h-full' src={item.image} alt='service' />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductService
