import { Listbox, Transition } from '@headlessui/react'
import axios from 'axios'
import { Fragment, useState } from 'react'
import { BsCheckLg, BsFillCaretDownFill } from 'react-icons/bs'
import ButtonOutline from 'src/components/atoms/ButtonOutline'
import ProductHeader from 'src/components/atoms/ProductHeader'
import Announcement from 'src/components/molecules/Announcement'
import ModalEccomerce from 'src/components/molecules/ModalEcommerce'
import CardProduct from 'src/components/orgainsms/CardProduct'
import CardLoader from 'src/components/orgainsms/CardProduct/CardLoader'
import useSwr from 'swr'


const fether = async (url) => {
    const response = await axios(url);
    return response.data
}

const filterList = [
    {
        name: 'Sort by'
    },
    {
        name: 'Produk',
    },
    {
        name: 'Terbaru'
    }
]

const ProductList = () => {
    const API = process.env.REACT_APP_API;
    const { data, isLoading } = useSwr(API + '/product', fether)
    const [selected, setSelected] = useState(filterList[0])
    let [isOpen, setIsOpen] = useState(false)
    const [listing, setListing] = useState(null)

    const handleOpenModal = (data) => {
        setIsOpen(true)
        setListing(data)
    }
    return (
        <>
            <div className="p-4 md:p-8 md:flex gap-3 items-center justify-end">
                <span className='text-[#282828]'>Sort By</span>
                <div className="w-full md:w-72">
                    <Listbox value={selected} onChange={setSelected}>
                        <div className="relative  mt-1">
                            <Listbox.Button className="relative w-full cursor-ponter border rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm">
                                <span className="block truncate">{selected.name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <BsFillCaretDownFill
                                        className="h-3 w-3"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {filterList.map((list, listIdx) => (
                                        <Listbox.Option
                                            key={listIdx}
                                            className={({ active }) =>
                                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-green-100 text-green-700' : 'text-gray-900'
                                                }`
                                            }
                                            value={list}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {list.name}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                                                            <BsCheckLg className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
            </div>
            <div className="px-4 md:px-8 hidden md:flex mb-12 h-[149px]">
                <Announcement
                    text="Explore our newest offerings and stay ahead of the trend with our constantly updated selection of innovative products."
                />
            </div>
            <div className='px-4 md:px-8'>
                {
                    isLoading && (
                        <div className='mb-20'>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4">
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                                <CardLoader />
                            </div>
                        </div>
                    )
                }
                {
                    data?.map(item => (
                        <div key={item.id} className='mb-20'>
                            <div className='flex items-center md:items-end mb-8 justify-between'>
                                <ProductHeader
                                    title={item.name}
                                />
                                <ButtonOutline title={"See more..."} />
                            </div>
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4">
                                {
                                    item.products.map(prd => (
                                        <CardProduct
                                            data={prd}
                                            key={prd.id}
                                            onClick={() => handleOpenModal(prd.produk_listing)}
                                            category={item.name}

                                        />

                                    ))
                                }
                            </div>
                        </div>
                    ))
                }

                {/* <div className='mb-20'>
                    <div className='flex items-center md:items-end mb-8 justify-between'>
                        <ProductHeader
                            title={"Camera DVR/NVR"}
                        />
                        <ButtonOutline title={"See more..."} />
                    </div>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                    </div>
                </div>
                <div className='mb-20'>
                    <div className='flex items-center md:items-end mb-8 justify-between'>
                        <ProductHeader
                            title={"Processor and Controller"}
                        />
                        <ButtonOutline title={"See more..."} />
                    </div>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                        <CardProduct

                            onClick={handleOpenModal}
                        />
                    </div>
                </div> */}
            </div>
            <ModalEccomerce
                data={listing}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </>
    )
}

export default ProductList