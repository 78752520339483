import axios from 'axios'
import { Link } from 'react-router-dom'
import useSwr from 'swr'

const fether = async url => {
  const response = await axios(url)
  return response.data
}

const Footer = () => {
  const API = process.env.REACT_APP_API
  const { data } = useSwr(API + '/category', fether)
  return (
    <>
      <div className='bg-black pb-6 pt-32 px-4 lg:px-0 text-white'>
        <div className='max-w-7xl mx-auto'>
          <div className='grid md:grid-cols-5'>
            <div className='col-span-2 text-slate-300 font-light'>
              <div className='mb-6 md:mb-12'>
                <img src='/logo/logo-footer.svg' alt='Angkasa Multimedia Solusi' />
                <div className='mt-4 md:mt-8 text-sm md:text-base'>
                  <p>
                    Ruko Citywalk Blok D1 No.08 Pondok Cabe <br />
                    Tangerang Selatan - Banten 15418
                  </p>
                </div>
              </div>
              <div className='hidden md:block'>
                <p>©2024 Angkasa Multimedia Solusi</p>
              </div>
            </div>
            <div className='col-span-5 md:col-span-3 mt-12 md:mt-0'>
              <div className='grid grid-cols-3'>
                <div>
                  <h3 className='font-semibold text-lg mb-5'>Shop For</h3>
                  <div className='text-sm lg:text-base flex text-slate-300 font-light gap-4 flex-col'>
                    <Link to='/service'>Services</Link>
                    <Link to='/product'>Product</Link>
                    {data?.map(item => (
                      <Link state={item} key={item.id} to={`/ctg/${item.slug}`}>
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className='font-semibold text-lg mb-5'>Engage</h3>
                  <div className='text-sm lg:text-base flex text-slate-300 font-light gap-4 flex-col'>
                    <Link to='/'>About Us</Link>
                    <Link to='/articles'>Articles</Link>
                    <Link to='/gallery'>Gallery</Link>
                    <Link to='/'>Privacy Policy</Link>
                  </div>
                </div>
                <div>
                  <h3 className='font-semibold text-lg mb-5'>Support</h3>
                  <div className='text-sm lg:text-base flex text-slate-300 font-light gap-4 flex-col'>
                    <Link to='/'>Contact Us</Link>
                    <Link to='/'>Become Partner</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='md:hidden text-slate-300 col-span-5 block pt-4 text-center text-xs'>
              © 2023 Angkasa Multimedia Solusi
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
