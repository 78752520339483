import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../molecules/Footer'
import Navbar from '../molecules/Navigation/Navbar'

const DefaultLayout = () => {
    return (
        <>
            <Navbar />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default DefaultLayout